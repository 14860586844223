<template>
  <div :class="['form-group', classComputed]">
    <label
      v-if="label"
      :id
      class="form-group__label"
    >
      {{ label }}
    </label>

    <slot />

    <small
      v-if="!!errorMessage"
      class="form-group__error"
    >
      {{ errorMessage }}
    </small>
  </div>
</template>

<script lang="ts" setup>
interface IProps {
  name?: string;
  label?: string;
  block?: boolean;
  error?: string | boolean | null;
}

const props = withDefaults(defineProps<IProps>(), {
  name: undefined,
  label: undefined,
  error: undefined,
});

const id = props?.name ? `${props.name}-${useId()}` : useId();

const classComputed = computed(() => {
  const classes = [
    {
      'is-block': props.block,
      'is-error': !!props.error,
    },
  ];

  return classes;
});

const errorMessage = computed(() => {
  return typeof props?.error === 'string' ? props.error : null;
});

provide('form-group', {
  id,
  name: computed(() => props.name),
  block: computed(() => props.block),
  error: computed(() => !!props.error),
});
</script>

<style lang="scss" src="./FormGroup.scss" />
